import { Link } from 'gatsby';
import React from 'react';

const Accueil = () => {
  return (
    <>
      <div id="slogan" className="effect1">
        <h1>
          <span className="accent4">Là.</span>{' '}
          <span className="accent3">Avec vous.</span>{' '}
        </h1>
      </div>

      <div id="boutons">
        <ul>
          <li className="bouton">
            <Link to="/equipe">
              <div className="gros-bouton">
                <img
                  src="/img/lemire-avocats-equipe.jpg"
                  width="240"
                  height="145"
                  alt="Équipe"
                  title="Lemire Avocats Équipe"
                />
                <div className="ligne-vert"></div>
                <h2>Équipe</h2>
              </div>
            </Link>
          </li>

          <li className="bouton">
            <Link to="/competences">
              <div className="gros-bouton">
                <img
                  src="/img/lemire-avocats-competences.jpg"
                  alt="Compétences"
                  title="Lemire Avocats Compétences"
                />
                <div className="ligne-vert"></div>
                <h2>Compétences</h2>
              </div>
            </Link>
          </li>

          <li className="bouton">
            <Link to="/cabinet">
              <div className="gros-bouton">
                <img
                  src="/img/lemire-avocats-cabinet.jpg"
                  alt="Cabinet"
                  title="Lemire Avocats Cabinet"
                />
                <div className="ligne-vert"></div>
                <h2>Cabinet</h2>
              </div>
            </Link>
          </li>
        </ul>
      </div>

      <div id="info">
        <div id="intro">
          <h1 className="slogan">
            Nous trouvons la <br />
            <span className="accent">meilleure solution</span> <br />
            pour vous dans le respect <br />
            de vos droits et vos besoins.
          </h1>
          <Link
            className="callToAction"
            to="/cabinet"
            title="Plus sur Lemire Lemire avocats s.e.n.c.r.l."
          >
            lire la suite
          </Link>
        </div>

        <div id="bg_effect"></div>
      </div>
    </>
  );
};

Accueil.propTypes = {};

export default Accueil;
