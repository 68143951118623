import React from 'react';

import { PAGES } from '../utils/utils';
import Accueil from '../components/fr/accueil';
import Layout from '../components/layout';
import SEO from '../components/seo';

const IndexPage = () => (
  <Layout page={PAGES.ACCUEIL}>
    <SEO title="Accueil" />
    <Accueil />
  </Layout>
);

export default IndexPage;
